import React from 'react';
import SEO from '../components/App/SEO';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import {Link} from 'gatsby';
import { graphql } from 'gatsby';

const dataSecurity =[
    {
        title: 'BlueMail Security',
        subtitle: 'Security and privacy at BlueMail is a top priority and our team maintains the highest levels of security standards, encryptions, and authorizations to keep your accounts, emails, and chats secure.',
        image: '/img/security/BM-security.svg',
        items: '',
        btn: '',
        secsubtitle: '',
        classes: 'security-first-section',
        icon: ''
    },
    {
        title: 'Security Highlights',
        subtitle: '',
        image: '',
        items: [
            {
                itemicon: "/img/security/protected-security.svg",
                itemtitle: 'Encryption',
                itemsubtext: 'BlueMail uses SSL and STARTTLS enforcing certificate check by default.',
                maxWidth: '30px', marginTop: '13%'
            },
            {
                itemicon: "/img/security/token-security.svg",
                itemtitle: 'Lockscreen',
                itemsubtext: 'BlueMail offers a secure Lock Screen feature, that utilizing passcode or fingerprint security',
                maxWidth: '30px', marginTop: '19%'
            },
            {
                itemicon: "/img/security/token-security.svg",
                itemtitle: 'OAuth Connections',
                itemsubtext: 'BlueMail uses the secure OAuth2 authorization protocol wherever possible for providers such as Gmail, Google Suite, Office365, and Yahoo, to access your account with a token only.',
                maxWidth: '30px', marginTop: '19%'
            },
            {
                itemicon: "/img/security/backup-security.svg",
                itemtitle: 'Account Backup & Recovery',
                itemsubtext: 'BlueMail’s Secure Account Backup and Recovery tool can really help you out when your phone gets lost or you simply want to upgrade to another device.',
                maxWidth: '35px', marginTop: '19%'
            },
          
        ],
        btn: '',
        secsubtitle: '',
        classes: 'security-second-section',
        icon: ''
    },
    {
        title: 'Protection of Your Data (EU GDPR Compliant)',
        subtitle: 'We’re committed to partnering with our Customers and Users to help them understand and prepare for the General Data Protection Regulation (GDPR). Besides strengthening and standardizing user data privacy across the EU nations, it will require new or additional obligations on all organizations that handle EU citizens’ personal data, regardless of where the organizations themselves are located.',
        image: '',
        items: '',
        btn: '',
        secsubtitle: '',
        classes: 'security-third-section',
        link: '/gdpr/'
    },
    {
        title: 'Have a security related question or issue?',
        subtitle: <span style={{color: '#777777'}}>
            
            If you believe you have found a security vulnerability on BlueMail, we encourage <br/> you to  <a href="mailto:security@bluemail.me" style={{color: '#216bf2', fontWeight: 'bold', textDecoration: 'none'}}>get in touch</a> with our security team right away.</span>,
        image: '',
        items: '',
        btn: '',
        secsubtitle: <span style={{color: '#777777'}}><br></br>Read more about our <Link to="/privacy/" style={{color: '#777777', fontWeight: 'bold', textDecoration: 'none'}}>Privacy Policy </Link> and <Link to="/tos" style={{color: '#777777', fontWeight: 'bold', textDecoration: 'none'}}>Terms of Service</Link>.</span>,
        classes: 'security-fourth-section',
        icon: "/img/security/lock-security.svg"
    },
]


const Security = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO
            postTitle="Security | BlueMail App"
            postDescription="Security and privacy at BlueMail is a top priority for our team. We use the leading industry protocols and standards to keep you and your data safe."
            postImage="/img/OG/og_image-security.png"
            postURL="security"
            postSEO
        />
        <div className="container pt-120 pb-100">
        {dataSecurity.map((dataSec, i) => {
            return (
                dataSec.classes !== 'security-third-section' ? 
                <div key={i} className={dataSec.classes}>
                        {dataSec.icon && <img src={dataSec.icon} style={{maxWidth: '50px', marginBottom: '20px'}} alt="lock-security" />}
                         <div style={{fontWeight: 'bold', color: '#000', textAlign: 'center', fontSize: '38px'}}>{dataSec.title}</div>
                        <div style={{maxWidth: '900px', margin: '10px auto', fontSize: '19px', textAlign: 'center', marginTop: '20px'}}>{dataSec.subtitle}</div>
                        {dataSec.image && <img style={{width: '48%', minWidth: '280px'}} src={dataSec.image} alt="why-you-need-BlueMail.png"/>}
                        {dataSec.items ? 
                            <div className="row" style={{marginTop: '50px'}}>
                                {dataSec.items ? dataSec.items.map((item, i) => {
                                    return (
                                        <div key={i} className="col-lg-6 col-sm-12">
                                            <div style={{background: '#fff', width: '60px', height: '55px',margin: 'auto', borderRadius: '50%', boxShadow: '0 3px 6px rgba(0,0,0,.16)'}}>
                                            <img src={item.itemicon} style={{maxWidth: item.maxWidth, marginTop: item.marginTop}} alt="backup security"/>
                                            </div>
                                            <div style={{margin: '15px auto', maxWidth: '300px', fontSize: '22px', fontWeight: 'bold'}}>{item.itemtitle}</div>
                                            <div style={{margin: '30px auto', maxWidth: '350px'}} className="items-subtext">{item.itemsubtext}</div>
                                        </div>
                                    );
                                }) : ''
                                }
                            </div>
                            : ''}
                        
                        <div style={{maxWidth: '900px', margin: '10px auto', fontSize: '18px'}}>{dataSec.secsubtitle}</div>
                </div>
                      : 
                      <div key={i} className={dataSec.classes}>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12" style={{textAlign: 'center'}}>
                                    <img style={{width: '82%', maxWidth: '82%'}} src={`/img/security/image-2.svg`} alt="sec-2"/>
                            </div>
                            <div className="col-lg-6 col-sm-12" style={{padding: '10px', alignSelf: 'center', maxWidth: '650px', margin: 'auto', textAlign: 'center'}}>
                                {dataSec.link ? 
                                <Link to={dataSec.link}><div style={{fontWeight: 'bold'}}>{dataSec.title}</div></Link> : 
                                <div style={{fontWeight: 'bold'}}>{dataSec.title}</div>}

                                <div style={{fontSize: '17px', marginTop: '7px'}}>{dataSec.subtitle}</div>
                            </div>
                        </div>      
                      </div>
            );
        })}
        </div>
        <Footer />
        </Layout>
    )
}

export default Security;

export const query = graphql`
query SecPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
